.artist-list {
    background-color: rgba(255,255,255,.1);
    min-height:100%;
}

.artist-list .artist-row .inner {
    font-size:18px;
    position:relative;
    white-space:nowrap;
    vertical-align: top;
}

.artist-list div.outer {
    padding:10px;
}

.artist-list sup {
    color:yellow;
}

.artist-list .check {
    color: lime;
    position: relative;
    font-size: 1rem;
    left: 2px;
    bottom: 3px;
}

.artist-list .song-title {
    overflow:hidden;
    text-overflow:ellipsis;
}

.artist-list .icons {
    position:relative;
    bottom:6px;
}

.artist-list .song-title, .artist-list .icons {
    display:inline-block
}