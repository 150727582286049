.fixed-header {
    top:0px;
    height:40px;
    background-color:#525760;
    text-align:center;
    box-shadow: 5px 0px 10px black;
    position:sticky;
  }

.fixed-header .fixed-header-username-area {
    font-size:22px;
    position:absolute;
    top:3px;
    left:4px;
    font-family: 'Courier New', Courier, monospace;
    cursor:pointer;
}

.fixed-header .fixed-header-username {
    display:inline-block;
    position:relative;
    top:2px;
}

.fixed-header .fixed-header-username:hover {
    text-decoration:underline;
}

.fixed-header .fixed-header-icons {
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
}

.fixed-header .fixed-header-options {
    position:absolute;
    right:5px;
    top:3px;    
}

.fixed-header .fixed-header-icons svg.highlighted {
    color: #007bff;
    transition:0.15s;
}

.fixed-header .fixed-header-icons svg:not(.highlighted) {
    color: inherit;
    transition:0.15s;
}

.fixed-header .fixed-header-icons svg {
    right:0px;
    vertical-align: center;
    margin:0px 2px;
    cursor:pointer;
    font-size:34px;
}