.App .App-logo {
  height: 40vmin;
  pointer-events: none;
}

#root, body, .App, .App .App-header {
  background-color: #282c34 !important;
  color: white;
}

body,
html,
.container {
  height: 100%;
  min-height: calc(100vh - 40px);
}

.container,
.content {
  display: flex;
}
.container {
  flex-direction: column;
}
.content {
  overflow: hidden;
}
.content,
.content div {
  flex: 1;
}

.artist-column {
  flex-basis:20%
}

h1 {
  font-size:18px !important;
}

.debug-info {
  position:absolute;
  bottom:10px;
  right:10px;
  background-color: rgba(0,0,0,.5);
  padding:20px;
  zoom:.7;
}

@media (max-width: 800px) {
  .debug-info {
    display:none;
  }
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

:focus { outline: none; }

a {
  color: #57a7ff !important;
  cursor: pointer
}

a:hover {
  color:#8fc4ff !important;
  text-decoration: underline !important;
}

.clear-search {
  position:absolute;
  
}

.requested-song-title {
  font-weight:bold;
  margin-top:15px;
}

#request-alert-dialog-title {
  padding-bottom: 5px;
}

.history-content td {
  font-size:10px;
  border: 1px solid gray;
}

.history-content th {
  padding-right:10px;
}